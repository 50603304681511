import React, {useMemo} from 'react';
import { LegacyStack, Text } from "@shopify/polaris";
import TextBlockItem from './textBlockItem';
import {TEXT_STYLE_TYPES} from '../../../../../../constants/constants';
import './styles.css';
import CardDivider from '../../../../../../components/cardDivider';
import FieldTitle from '../../../../../../components/fieldTitle';

const TextSettingsBlock = (props) => {
  const {state, heading, blockType, lastElement} = props;

  const renderBlockItems = useMemo(() => {
    return TEXT_STYLE_TYPES.map(({styleName, styleTitle}, index) => {
      return <TextBlockItem key={index} type={styleName} targetText={blockType} title={styleTitle} {...props}/>
    })
  }, [state, blockType]);

  return (
    <LegacyStack vertical={true} spacing='tight'>
      {
        heading &&
        <FieldTitle title={heading} />
      }
      {renderBlockItems}
      {!lastElement && <CardDivider /> }
    </LegacyStack>
  );
};

TextSettingsBlock.propTypes = {};

export default TextSettingsBlock;